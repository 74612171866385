<template>
  <div class="extra-wrapper">
    <!--        <div class="extra-item">-->
    <!--          <a @click="searchDate = [moment().startOf('day'),moment().endOf('day')]" :class="[activeDate === 'day' ? 'currentDate':'']">{{ $t('dashboard.analysis.all-day') }}</a>-->
    <!--          <a @click="searchDate = [moment().startOf('week'),moment().endOf('week')]" :class="[activeDate === 'week' ? 'currentDate':'']">{{ $t('dashboard.analysis.all-week') }}</a>-->
    <!--          <a @click="searchDate = [moment().startOf('month'),moment().endOf('month')]" :class="[activeDate === 'month' ? 'currentDate':'']">{{ $t('dashboard.analysis.all-month') }}</a>-->
    <!--          <a @click="searchDate = [moment().startOf('year'),moment().endOf('year')]" :class="[activeDate === 'year' ? 'currentDate':'']">{{ $t('dashboard.analysis.all-year') }}</a>-->
    <!--        </div>-->
    <a-form :model="form" layout="inline">
      <a-form-item style="width: 13%">
        <a-select v-model:value="type" style="width:100%" allowClear placeholder="类型">
          <a-select-option :value="0">挂账对象</a-select-option>
          <a-select-option :value="1">客户对象</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item style="width: 13%">
        <a-select v-model:value="orgId" allowClear placeholder="办事处">
          <a-select-option v-for="item in orgs" :key="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item style="width: 27%">
        <a-range-picker v-model:value="searchDate" style="width:100%" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"
          :allowClear="false" :placeholder="['开始日期', '结束日期']" />
      </a-form-item>
      <a-form-item style="width: 13%">
        <a-input v-model:value="userName" placeholder="责任人" />
      </a-form-item>
      <a-form-item style="width: 13%">
        <a-input v-model:value="customerName" placeholder="客户对象" />
      </a-form-item>
      <a-form-item style="width: 13%">
        <a-space>
          <a-button type="primary" @click="loadData">查询</a-button>
          <a-button type="primary" @click="loadExport('', '')">导出明细</a-button>
          <a-button type="primary" @click="loadTotalExport">导出汇总</a-button>
        </a-space>
      </a-form-item>
      <a-form-item style="width: 100%">
        <a-space>
          <a-button type="primary" @click="newTotalExport()" danger>新版应收应付汇总</a-button>
          <a-button type="primary" @click="newTransExport()" danger>新版应付明细</a-button>
          <a-button type="primary" @click="newOrderPayExport()" danger>新版代收明细</a-button>
          <a-button type="primary" @click="newOrderExport()" danger>新版应收明细</a-button>
          <a-button type="primary" @click="newOrderInnerExport()" danger>新版内部代收明细</a-button>
        </a-space>
      </a-form-item>
    </a-form>

  </div>
  <a-table :dataSource="dataSource" :rowKey="record => record.customerId" :loading="loading"
    :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" bordered>
    <a-table-column :title="title" data-index="creditor">
      <template #default="{ text, record }">
        <a @click="detail(record.creditor, record.officeOrgName)">{{ text }}</a>
      </template>
    </a-table-column>
    <a-table-column title="类型" data-index="creditType.label" />
    <a-table-column title="办事处" data-index="officeOrgName" />
    <a-table-column title="月份" data-index="month" />
    <a-table-column title="总金额" data-index="total" />
    <a-table-column title="合同" data-index="order" />
    <a-table-column title="到收" data-index="orderPay" />
    <a-table-column title="运费" data-index="trans" />
    <a-table-column title="返款" data-index="refund" />
  </a-table>
  <a-modal v-model:visible="visiblemodal" title="明细详情" footer={ [] } width="80%">
    <a-button type="primary" @click="loadExport(1, 1)">导出明细</a-button>
    <div>
      <a-table :dataSource="dataDetailSource" :rowKey="record => record.id" :loading="loading"
        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" bordered>
        <a-table-column :title="title" data-index="creditor" />
        <a-table-column title="类型" data-index="creditType.label" />
        <a-table-column title="办事处" data-index="officeOrgName" />
        <a-table-column title="推送时间" data-index="pushTime" />
        <a-table-column title="客户" data-index="customerName" />
        <a-table-column title="客户类型" data-index="businessType.label" />
        <a-table-column title="金额" data-index="settableAmt" />
        <a-table-column title="责任对象" data-index="creditor" />
        <a-table-column title="合同ID/运单ID" data-index="id" />
        <a-table-column title="收付类型" data-index="type" />
      </a-table>
    </div>
  </a-modal>
</template>
<script>
import { receivablePayable, receivablePayableExport, receivablePayableDetail, receivablePayableDetailExport, receivablePayableNewTransExport, receivablePayableNewExport, receivablePayableNewArrivalExport, receivablePayableNewOrderExport, receivablePayableNewOrderInnerExport } from '@/api/trans/finance/bill'
import { fuzzy } from '@/api/crm/carrer/carrier'

import { reactive, toRefs } from 'vue'
import { onMounted, watch } from '@vue/runtime-core'
import { useStore } from 'vuex'
import moment from 'moment'
import { list as orgList } from '@/api/system/organzition'
export default {
  setup () {
    const store = useStore()
    const state = reactive({
      orgs: [],
      creditor: '',
      officeOrgName: '',
      visiblemodal: false,
      loading: false,
      type: 0,
      title: '挂账对象',
      // searchDate: [moment(), moment()],
      searchDate: [],
      userName: undefined,
      customerName: undefined,
      searchKey: null,
      carriers: [],
      dataSource: [],
      dataDetailSource: [],
      orgId: undefined
    })
    watch(() => state.type, (type) => {
      if (type === 0) {
        state.title = '挂账对象'
      } else if (type === 1) {
        state.title = '客户对象'
      }
      loadData()
    })
    // const activeDate = computed(() => {
    //   if (moment().startOf('day').isSame(state.searchDate[0], 'date') && moment().endOf('day').isSame(state.searchDate[1], 'date')) return 'day'
    //   else if (moment().startOf('week').isSame(state.searchDate[0], 'date') && moment().endOf('week').isSame(state.searchDate[1], 'date')) return 'week'
    //   else if (moment().startOf('month').isSame(state.searchDate[0], 'date') && moment().endOf('month').isSame(state.searchDate[1], 'date')) return 'month'
    //   else if (moment().startOf('year').isSame(state.searchDate[0], 'date') && moment().endOf('year').isSame(state.searchDate[1], 'date')) return 'year'
    //   else return ''
    // })

    const loadData = () => {
      state.loading = true
      receivablePayable({
        orgId: state.orgId,
        type: state.type,
        customerName: state.customerName,
        userName: state.userName,
        startTime: state.searchDate[0],
        endTime: state.searchDate[1]
      }).then(res => {
        if (res.code === 10000) {
          state.dataSource = res.data
        }
      })
      state.loading = false
    }

    const loadExport = (creditor, officeOrgName) => {
      state.loading = true
      if (creditor === '') {
        state.creditor = ''
        state.officeOrgName = ''
      }
      receivablePayableDetailExport({
        creditor: state.creditor,
        officeOrgName: state.officeOrgName,
        startTime: state.searchDate[0],
        endTime: state.searchDate[1]
      }, '应收应付明细').then(res => {
      })
      state.loading = false
    }
    const loadTotalExport = () => {
      state.loading = true
      receivablePayableExport({
        startTime: state.searchDate[0],
        endTime: state.searchDate[1]
      }, '应收应付汇总').then(res => {
      })
      state.loading = false
    }
    const newTotalExport = () => {
      receivablePayableNewExport({
        startTime: state.searchDate[0],
        endTime: state.searchDate[1]
      }, '应收应付汇总').then(res => {
      })
    }
    const newTransExport = () => {
      receivablePayableNewTransExport({
        startTime: state.searchDate[0],
        endTime: state.searchDate[1]
      }, '应收应付明细').then(res => {
      })
    }
    const newOrderPayExport = () => {
      receivablePayableNewArrivalExport({
        startTime: state.searchDate[0],
        endTime: state.searchDate[1]
      }, '应收应付明细').then(res => {
      })
    }
    const newOrderExport = () => {
      receivablePayableNewOrderExport({
        startTime: state.searchDate[0],
        endTime: state.searchDate[1]
      }, '应收应付明细').then(res => {
      })
    }

    const newOrderInnerExport = () => {
      receivablePayableNewOrderInnerExport({
        startTime: state.searchDate[0],
        endTime: state.searchDate[1]
      }, '应收应付明细').then(res => {
      })
    }
    orgList({ orgType: 1 }).then(res => {
      state.orgs = res.data
    })
    state.orgs = store.state.app.orgTypeList
    onMounted(()=>{
      loadData()
      document.querySelector('.ant-page-container').style.background = '#fff'
    })

    const carrierAutoComplete = val => {
      if (val.length > 1) {
        if (state.timeOut !== null) {
          clearTimeout(state.timeOut)
        }
        state.timeOut = setTimeout(() => {
          fuzzy({ name: val }).then(res => {
            if (res.code === 10000) { state.carriers = res.data } else state.carriers = []
          })
        }, 500)
      } else {
        state.carriers = []
      }
    }

    const detail = (creditor, officeOrgName) => {
      state.creditor = creditor
      state.officeOrgName = officeOrgName
      state.visiblemodal = true
      receivablePayableDetail({
        creditor: creditor,
        officeOrgName: officeOrgName,
        startTime: state.searchDate[0],
        endTime: state.searchDate[1]
      }).then(res => {
        state.dataDetailSource = res.data
      })
    }
    return {
      ...toRefs(state),
      loadData,
      loadExport,
      loadTotalExport,
      newTotalExport,
      newOrderExport,
      newOrderInnerExport,
      newOrderPayExport,
      newTransExport,
      moment,
      detail,
      carrierAutoComplete
    }
  }

}
</script>
<style lang="less" scoped>
:deep(.ant-form-item) {
  margin-bottom: 10px;
}
:deep(.ant-page-container){
  background: #fff;
}
.extra-wrapper {
  padding:10px;
  height:100%;
  background: #fff;

  .extra-item {
    display: inline-block;
    margin-right: 24px;

    a {
      margin-left: 24px;
      color: rgba(0, 0, 0, 0.85);
    }

    a.currentDate,
    a:hover {
      color: #1890ff;
    }
  }
}
</style>
